import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { updateOrderStatus } from '../store/actions/appActions';
import Status from '../components/Status';
import { useNavigate, useParams } from 'react-router-dom';
import './main.css';
import moment from 'moment-timezone';
import HubSpotLink from './HubSpotLink';
import { fetchOrderDataById } from '../store/actions/appActions';
import Spinner from './Spinner';
import {
  portalToolkitSKU,
  schemaReplicatorSKU,
  portalSyncSKU,
  hsmSchLiteProductCode,
  hsmSchProProductCode,
  hsmSchProductCodes,
} from '../utils/productSKU';

const pageUrl = new URL(window.location.href);
const baseUrl = pageUrl.origin;

const getButtonByStatus = (status, orderId, productCode, handleMigration) => {
  const buttonConfig = {
    Planned: {
      label: `Start ${
        hsmSchProductCodes.includes(productCode)
          ? 'Replication'
          : productCode === portalSyncSKU
          ? 'Sync'
          : 'Migration'
      }`,
      action: () => handleMigration(orderId, 'start'),
    },
    Running: {
      label: `Stop ${
        hsmSchProductCodes.includes(productCode)
          ? 'Replication'
          : productCode === portalSyncSKU
          ? 'Sync'
          : 'Migration'
      }`,
      action: () => handleMigration(orderId, 'stop'),
    },
    Started: {
      label: `Stop ${
        hsmSchProductCodes.includes(productCode)
          ? 'Replication'
          : productCode === portalSyncSKU
          ? 'Sync'
          : 'Migration'
      }`,
      action: () => handleMigration(orderId, 'stop'),
    },
    Paused: {
      label: `Restart ${
        hsmSchProductCodes.includes(productCode)
          ? 'Replication'
          : productCode === portalSyncSKU
          ? 'Sync'
          : 'Migration'
      }`,
      action: () => handleMigration(orderId, 'restart'),
    },
    Stopped: {
      label: `Restart ${
        hsmSchProductCodes.includes(productCode)
          ? 'Replication'
          : productCode === portalSyncSKU
          ? 'Sync'
          : 'Migration'
      }`,
      action: () => handleMigration(orderId, 'restart'),
    },
    Completed: {
      label:
        productCode === portalToolkitSKU
          ? 'Re-Run Migration'
          : productCode === portalSyncSKU
          ? 'Restart Sync'
          : `${
              hsmSchProductCodes.includes(productCode)
                ? 'Replication'
                : 'Migration'
            } Completed`,
      style:
        productCode === portalToolkitSKU || productCode === portalSyncSKU
          ? {}
          : { backgroundColor: 'grey', cursor: 'not-allowed' },
      action:
        productCode === portalToolkitSKU || productCode === portalSyncSKU
          ? () => handleMigration(orderId, 'restart')
          : () => {},
    },
    Failed: {
      label: `${
        hsmSchProductCodes.includes(productCode)
          ? 'Replication'
          : productCode === portalSyncSKU
          ? 'Sync'
          : 'Migration'
      } Failed`,
      style: { backgroundColor: 'grey', cursor: 'not-allowed' },
    },
    Unknown: {
      label: 'Unknown Status',
      style: { backgroundColor: 'grey', cursor: 'not-allowed' },
    },
  };

  const config = buttonConfig[status] || {
    label: 'Unknown Status',
    style: { backgroundColor: 'grey', cursor: 'not-allowed' },
  };

  return (
    <div className="emailbtn" onClick={config.action} style={config.style}>
      {config.label}
    </div>
  );
};

const cooldownTime = 60000;
const editCooldownTime = 30000;

const disableButton = (coolDownProcess, id, setCooldownState) => {
  let lastPress;
  const isRefreshing = coolDownProcess === 'refresh';
  if (isRefreshing) {
    lastPress = localStorage.getItem(`refreshCooldown_${id}`);
  } else {
    lastPress = localStorage.getItem(`migrationCooldown_${id}`);
  }

  const duration = cooldownTime - (Date.now() - lastPress);
  const editDuration = editCooldownTime - (Date.now() - lastPress);

  if (duration > 0) {
    setCooldownState((prevState) => ({
      ...prevState,
      [id]: {
        isCooldown: true,
        cooldownSeconds: Math.ceil(duration / 1000),
        ...(!isRefreshing && {
          isEditCooldown: true,
          editCooldownSeconds: Math.ceil(editDuration / 1000),
        }),
      },
    }));

    const interval = setInterval(() => {
      setCooldownState((prevState) => {
        const remaining = prevState[id].cooldownSeconds - 1;
        const editRemaining = prevState[id].editCooldownSeconds - 1;
        if (remaining > 0) {
          return {
            ...prevState,
            [id]: {
              ...prevState[id],
              cooldownSeconds: remaining,
              ...(!isRefreshing && {
                isEditCooldown: editRemaining > 0,
                editCooldownSeconds: editRemaining > 0 ? editRemaining : 0,
              }),
            },
          };
        } else {
          clearInterval(interval);
          return {
            ...prevState,
            [id]: {
              isCooldown: false,
              cooldownSeconds: 0,
              isEditCooldown: false,
              editCooldownSeconds: 0,
            },
          };
        }
      });
    }, 1000);
  } else {
    setCooldownState((prevState) => ({
      ...prevState,
      [id]: {
        isCooldown: false,
        cooldownSeconds: 0,
        isEditCooldown: false,
        editCooldownSeconds: 0,
      },
    }));
  }
};

const checkCooldown = async (coolDownProcess, id, setCooldownState) => {
  let lastPress;
  if (coolDownProcess === 'refresh') {
    lastPress = localStorage.getItem(`refreshCooldown_${id}`);
  } else {
    lastPress = localStorage.getItem(`migrationCooldown_${id}`);
  }

  if (lastPress) {
    const now = Date.now();
    const timePassed = now - lastPress;
    if (timePassed < cooldownTime) {
      disableButton(coolDownProcess, id, setCooldownState);
    } else {
      if (coolDownProcess === 'refresh') {
        localStorage.removeItem(`refreshCooldown_${id}`);
      } else {
        localStorage.removeItem(`migrationCooldown_${id}`);
      }
      setCooldownState((prevState) => ({
        ...prevState,
        [id]: {
          isCooldown: false,
          cooldownSeconds: 0,
          isEditCooldown: false,
          editCooldownSeconds: 0,
        },
      }));
    }
  } else {
    setCooldownState((prevState) => ({
      ...prevState,
      [id]: {
        isCooldown: false,
        cooldownSeconds: 0,
        isEditCooldown: false,
        editCooldownSeconds: 0,
      },
    }));
  }
};

const handleRefresh = (orderId, dispatch, setCooldownState) => {
  localStorage.setItem(`refreshCooldown_refresh-${orderId}`, Date.now());
  disableButton('refresh', `refresh-${orderId}`, setCooldownState);
  dispatch(fetchOrderDataById(orderId));
};

const DetailsComponent = () => {
  const [cooldownState, setCooldownState] = useState({});
  const [cooldownChecked, setCooldownChecked] = useState({
    migration: false,
    refresh: false,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const hsmOrders = useSelector((state) => state.app.hsmOrders);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.loading);
  const hasInitialized = useSelector((state) => state.app.hasInitialized);
  const [loadingMigration, setLoadingMigration] = useState(false);
  const { portalToolkitData, schemaReplicatorData, portalSyncData } =
    useSelector((state) => state.app);
  const isCooldown = useMemo(
    () => ({
      migration: cooldownState[id]?.isCooldown || false,
      edit: cooldownState[id]?.isEditCooldown || false,
      refresh: cooldownState[`refresh-${id}`]?.isCooldown || false,
    }),
    [cooldownState, id]
  );
  const cooldownSeconds = useMemo(
    () => ({
      migration: cooldownState[id]?.cooldownSeconds || 0,
      edit: cooldownState[id]?.editCooldownSeconds || 0,
      refresh: cooldownState[`refresh-${id}`]?.cooldownSeconds || 0,
    }),
    [cooldownState, id]
  );

  useEffect(() => {
    if (!hasInitialized) {
      dispatch(fetchOrderDataById(id));
    }
  }, [hasInitialized, id, dispatch]);

  useEffect(() => {
    const performCheck = async () => {
      await checkCooldown('refresh', `refresh-${id}`, setCooldownState);
      await checkCooldown('migration', id, setCooldownState);
      setCooldownChecked({
        migration: true,
        refresh: true,
      }); // Set cooldownChecked immediately after check
    };

    performCheck();
  }, [id]);

  const handleMigration = (orderId, migrationStatus) => {
    setLoadingMigration(true);
    const now = Date.now();
    localStorage.setItem(`migrationCooldown_${orderId}`, now);
    disableButton('migration', orderId, setCooldownState);
    fetch(`${process.env.REACT_APP_API_URL}/api/migrations/changestatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        migrationId: orderId,
        migrationStatus: migrationStatus,
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Status changed successfully', response);
          // dispatch(fetchOrderDataById(orderId));
          dispatch(updateOrderStatus(orderId, migrationStatus));
          toast.success('Status changed successfully');
        } else {
          response.status === 401
            ? toast.error(
                `${
                  migrationStatus[0].toUpperCase() + migrationStatus.slice(1)
                } feature didn't work properly, please refresh page and try again`
              )
            : toast.error('Error in changing status');
        }
      })
      .catch((error) => {
        console.log(error, 'error');
        toast.error('Error in changing status');
      })
      .finally(() => {
        setLoadingMigration(false);
      });
  };

  if (
    loading ||
    loadingMigration ||
    !cooldownChecked.migration ||
    !cooldownChecked.refresh
  ) {
    return <Spinner label="Loading..." />;
  }

  const orders =
    hsmOrders?.orders ||
    portalToolkitData?.operations ||
    schemaReplicatorData?.operations ||
    portalSyncData?.operations ||
    [];
  const order =
    orders.find((u) => u.id === id) ||
    portalToolkitData?.operations?.find((u) => u.id === id) ||
    schemaReplicatorData?.operations?.find((u) => u.id === id) ||
    portalSyncData?.operations?.find((u) => u.id === id);

  if (!order) {
    return <div>Order not found</div>;
  }

  const returnToApp = (productCode) => {
    if (['HSMLITE', 'HSMPRO', 'HSMENT'].includes(productCode)) {
      navigate('/account/my-app-information-item/HSM');
    } else if (hsmSchProductCodes.includes(productCode)) {
      navigate(`/account/my-app-information-item/${schemaReplicatorSKU}`);
    } else {
      navigate(`/account/my-app-information-item/${productCode}`);
    }
  };

  const enableEditBtn = (order) => {
    const { productCode } = order;
    if (['HSMLITE', 'HSMPRO', 'HSMENT'].includes(productCode)) {
      return !['Running', 'Started', 'Completed'].includes(order.status);
    } else {
      return ['Planned', 'Stopped', 'Completed'].includes(order.status);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="app-container">
        <div className="detailintro">
          {order.productCode === portalToolkitSKU ? (
            <div className="gridheader1">HubSpot Portal Toolkit</div>
          ) : order.productCode === portalSyncSKU ? (
            <div className="gridheader1">HubSpot Portal Sync</div>
          ) : ['HSMLITE', 'HSMPRO', 'HSMENT'].includes(order.productCode) ? (
            <div className="gridheader1">HubSpot to HubSpot Migrator</div>
          ) : (
            <div className="gridheader1">HubSpot Schema Replicator</div>
          )}
          <div className="gridheader2">
            {order.orderDate
              ? moment(order.orderDate)
                  .tz('America/New_York')
                  .format('MM/DD/YYYY')
              : 'Not Available'}
          </div>
          <div className="gridheader3">
            <Status status={order.status} />
          </div>
          <div
            className="gridheader4"
            style={{
              fontSize: '12px',
              color: 'var(--clr-purple)',
              fontWeight: 'var(--fw-bold)',
              textDecoration: 'underline',
              textAlign: 'end',
              cursor: 'pointer',
            }}
            onClick={() => returnToApp(order.productCode)}
          >
            Go Back
          </div>
        </div>
        <div className="detailgrid">
          <div className="detailheading gridpurchase">
            {order.productCode === portalToolkitSKU
              ? 'MIGRATION INFO'
              : order.productCode === portalSyncSKU
              ? 'SYNC INFO'
              : 'PURCHASE INFO'}
          </div>
          <div id="orderinfo" className="detailheading gridorder">
            {order.productCode === portalToolkitSKU ||
            order.productCode === portalSyncSKU
              ? 'NOTIFICATION INFO'
              : 'ORDER INFO'}
          </div>
          <div id="datainfo" className="detailheading griddatabase">
            STATUS
          </div>
          <div id="password" className="detailheading gridpassword">
            ACTION
          </div>
          <div className="row-border gridline"></div>
          <div className="row-border gridline2"></div>
          <div className="row-border gridline3"></div>
          <div className="row-border gridline4"></div>

          {/* ROW 1 */}
          <div className="detailheading gridpurchase2">
            <div className="detailfield">
              <div>
                {order.productCode === portalToolkitSKU
                  ? 'Migration Name'
                  : order.productCode === portalSyncSKU
                  ? 'Sync Name'
                  : 'Version'}
              </div>
              <div style={{ fontWeight: 'var(--fw-bold)' }}>
                {order.productCode === portalToolkitSKU ? (
                  order.alias
                ) : order.productCode === portalSyncSKU ? (
                  order.alias || order.productName
                ) : hsmSchProductCodes.includes(order.productCode) ? (
                  order.productName
                ) : (
                  <>
                    {order.productCode === 'HSMENT'
                      ? 'Enterprise'
                      : order.productCode === 'HSMPRO'
                      ? 'Pro'
                      : order.productCode === 'HSMLITE'
                      ? 'Lite'
                      : order.productCode === 'HSMSCH'
                      ? 'Free'
                      : ''}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="detailheading gridorder2">
            <div className="detailfield">
              <div>Email</div>
              <div
                title={order.email}
                style={{
                  fontWeight: 'var(--fw-bold)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {order.email}
              </div>
            </div>
          </div>

          <div className="detailheading griddatabase2">
            <div className="detailfield">
              <div>Last Update Time</div>
              <div style={{ fontWeight: 'var(--fw-bold)' }}>
                {order.lastHeartbeat
                  ? moment(order.lastHeartbeat)
                      .tz('America/New_York')
                      .format('MM/DD/YY, h:mm:ss A')
                  : 'Not Available'}
              </div>
            </div>
          </div>
          <div
            className="btnContainer detailheading gridpassword2"
            style={{ justifySelf: 'end' }}
          >
            {!isCooldown.refresh ? (
              <div
                className={'emailbtn'}
                onClick={() =>
                  handleRefresh(order.id, dispatch, setCooldownState)
                }
              >
                Refresh Status
              </div>
            ) : (
              <div
                className="emailbtn"
                style={{ backgroundColor: 'grey', cursor: 'not-allowed' }}
              >
                Cooldown {cooldownSeconds.refresh}s
              </div>
            )}
            {!isCooldown.migration ? (
              getButtonByStatus(
                order.status,
                order.id,
                order.productCode,
                handleMigration
              )
            ) : (
              <div
                className="emailbtn"
                style={{ backgroundColor: 'grey', cursor: 'not-allowed' }}
              >
                Cooldown {cooldownSeconds.migration}s
              </div>
            )}
            {!isCooldown.edit ? (
              enableEditBtn(order) ? (
                <div
                  className={`emailbtn ${
                    hsmSchProductCodes.includes(order.productCode) &&
                    schemaReplicatorData?.subscription?.productCode ===
                      hsmSchLiteProductCode
                      ? 'disabled-grey popover'
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      !hsmSchProductCodes.includes(order.productCode) ||
                      schemaReplicatorData?.subscription?.productCode ===
                        hsmSchProProductCode
                    ) {
                      const reconfigUrl = order.reconfigurationUrl;
                      const redirectParam = `redirectUrl=${baseUrl}/account/my-app-information-item/app/${order.id}`;
                      const separator = reconfigUrl.includes('?') ? '&' : '?';

                      const finalUrl = `${reconfigUrl}${separator}${redirectParam}`;

                      window.location.href = finalUrl;
                    }
                  }}
                >
                  Edit{' '}
                  {hsmSchProductCodes.includes(order.productCode)
                    ? 'Replication'
                    : order.productCode === portalSyncSKU
                    ? 'Sync'
                    : 'Migration'}
                </div>
              ) : (
                <div
                  className="emailbtn"
                  style={{ backgroundColor: 'grey', cursor: 'not-allowed' }}
                >
                  Edit{' '}
                  {hsmSchProductCodes.includes(order.productCode)
                    ? 'Replication'
                    : order.productCode === portalSyncSKU
                    ? 'Sync'
                    : 'Migration'}
                </div>
              )
            ) : (
              <div
                className="emailbtn"
                style={{ backgroundColor: 'grey', cursor: 'not-allowed' }}
              >
                Cooldown {cooldownSeconds.edit}s
              </div>
            )}
          </div>

          {/* ROW 2 */}
          <div className="detailheading gridpurchase3">
            <div className="detailfield">
              <div>Source Portal ID</div>
              {order.sourcePortalId ? (
                <HubSpotLink id={order.sourcePortalId} />
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="detailheading gridorder3">
            <div className="detailfield">
              <div>Full Name</div>
              <div
                title={order.email}
                style={{
                  fontWeight: 'var(--fw-bold)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {hsmSchProductCodes.includes(order.productCode)
                  ? order.alias
                  : order.name}
              </div>
            </div>
          </div>

          <div className="detailheading griddatabase3">
            <div className="detailfield">
              <div>Last Status Email</div>
              <div style={{ fontWeight: 'var(--fw-bold)' }}>
                {order.lastMailStatusUpdate
                  ? moment(order.lastMailStatusUpdate)
                      .tz('America/New_York')
                      .format('MM/DD/YY, h:mm:ss A')
                  : 'Not Available'}
              </div>
            </div>
          </div>

          {/* ROW 3 */}
          <div className="detailheading gridpurchase4">
            <div className="detailfield">
              <div>Destination Portal ID</div>
              <a
                href={`https://app.hubspot.com/settings/${order.targetPortalId}/account-defaults/general`}
                style={{
                  fontWeight: 'var(--fw-bold)',
                  cursor: 'pointer',
                  color: '#E8662E',
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {order.targetPortalId ? order.targetPortalId : ''}
              </a>
            </div>
          </div>

          <div className="detailheading gridorder4">
            <div className="detailfield">
              <div>Order Creation Date</div>
              <div style={{ fontWeight: 'var(--fw-bold)' }}>
                {order.orderDate
                  ? moment(order.orderDate)
                      .tz('America/New_York')
                      .format('MM/DD/YY, h:mm:ss A')
                  : 'Not Available'}
              </div>
            </div>
          </div>

          <div className="detailheading griddatabase4">
            <div className="detailfield">
              <div>Current Progress</div>
              <div style={{ fontWeight: 'var(--fw-bold)' }}>
                {order.currentProgress}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsComponent;
